import { HeatPumpLeadContextInterface } from "@/types/heatPumpLead";
import * as React from "react";

export const HeatPumpLeadContext =
	React.createContext<HeatPumpLeadContextInterface>({
		postcode: "",
		setPostcode: (postcode: string) => {},
		firstName: "",
		setFirstName: (firstName: string) => {},
		lastName: "",
		setLastName: (lastName: string) => {},
		contactNumber: "",
		setContactNumber: (contactNumber: string) => {},
		email: "",
		setEmail: (email: string) => {},
		existingSystem: "",
		setExistingSystem: (existingSystem: string) => {},
		homeOrBusiness: "",
		setHomeOrBusiness: (homeOrBusiness: string) => {},
		ownerOrTenant: "",
		setOwnerOrTenant: (ownerOrTenant: string) => {},
		propertyType: "",
		setPropertyType: (propertyType: string) => {},
		typeOfInsulation: "",
		setTypeOfInsulation: (typeOfHeatPump: string) => {},
		clearState: () => {},
	});

const HeatPumpLeadProvider: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	const [email, setEmail] = React.useState<string>("");
	const [lastName, setLastName] = React.useState<string>("");
	const [postcode, setPostcode] = React.useState<string>("");
	const [firstName, setFirstName] = React.useState<string>("");
	const [propertyType, setPropertyType] = React.useState<string>("");
	const [ownerOrTenant, setOwnerOrTenant] = React.useState<string>("");
	const [contactNumber, setContactNumber] = React.useState<string>("");
	const [existingSystem, setExistingSystem] = React.useState<string>("");
	const [homeOrBusiness, setHomeOrBusiness] = React.useState<string>("");
	const [typeOfInsulation, setTypeOfInsulation] = React.useState<string>("");

	const clearState = () => {
		setFirstName("");
		setLastName("");
		setEmail("");
		setContactNumber("");
		setPostcode("");
	};

	return (
		<HeatPumpLeadContext.Provider
			value={{
				postcode,
				setPostcode,
				firstName,
				setFirstName,
				lastName,
				setLastName,
				contactNumber,
				setContactNumber,
				email,
				setEmail,
				existingSystem,
				setExistingSystem,
				homeOrBusiness,
				setHomeOrBusiness,
				ownerOrTenant,
				setOwnerOrTenant,
				propertyType,
				setPropertyType,
				typeOfInsulation,
				setTypeOfInsulation,
				clearState,
			}}
		>
			{children}
		</HeatPumpLeadContext.Provider>
	);
};

export default HeatPumpLeadProvider;
