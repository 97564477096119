import Head from "next/head";
import "../styles/globals.css";
import * as React from "react";
import Script from "next/script";
import type { AppProps } from "next/app";
import "bootstrap/dist/css/bootstrap.css";
import LeadProvider from "@/context/lead";
import UtmProvider from "@/context/utmContext";
import KeywordProvider from "@/context/keyword";
import GoogleTagManager from "@/components/Shared/GoogleTagManager";
import HeatPumpLeadProvider from "@/context/heatPumpLead";
import SolarProvider from "@/context/solar";
import "bootstrap-icons/font/bootstrap-icons.css";

const MyApp = ({ Component, pageProps }: AppProps) => {
	return (
		<>
			<Head>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, maximum-scale=1"
				/>
				<meta name="theme-color" content="#00263A" />
				<meta name="verification" content="1cef0f7fb213179c2c9830c8b1d58b2c" />
				<meta
					name="facebook-domain-verification"
					content="u1476s1qcstzpz56lbpo9vh5zsm3on"
				/>
				<link rel="icon" type="image/png" sizes="32x32" href="/favicon.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/favicon.png" />
			</Head>
			<GoogleTagManager />
			<Script
				id="google-optimize"
				src={`https://www.googleoptimize.com/optimize.js?id=${process.env.GOOGLE_OPTIMIZE}`}
			/>
			<noscript>
				<iframe
					width="0"
					height="0"
					style={{ display: "none", visibility: "hidden" }}
					src={`https://www.googletagmanager.com/ns.html?id=${process.env.GOOGLE_ANALYTICS}`}
				/>
			</noscript>
			<div>
				<KeywordProvider>
					<UtmProvider>
						<LeadProvider>
							<HeatPumpLeadProvider>
								<SolarProvider>
									<Component {...pageProps} />
								</SolarProvider>
							</HeatPumpLeadProvider>
						</LeadProvider>
					</UtmProvider>
				</KeywordProvider>
			</div>
		</>
	);
};

export default MyApp;
