import * as React from "react";
import Cookies from "js-cookie";
import { useRouter } from "next/router";

export type KeywordContextInterface = {
	source: string | undefined;
	setSource: (source: string) => void;
	sclid: string | undefined;
	setSclid: (sclid: string) => void;
	gclid: string | undefined;
	setGclid: (gclid: string) => void;
	msclkid: string | undefined;
	setMsclkid: (msclkid: string) => void;
	fbclid: string | undefined;
	setFbclid: (fbclid: string) => void;
	campaignid: string | undefined;
	setCampaignid: (campaignid: string) => void;
	adid: string | undefined;
	setAdid: (adid: string) => void;
	adsetid: string | undefined;
	setAdsetid: (adsetid: string) => void;
	targetid: string | undefined;
	setTargetid: (targetid: string) => void;
	adgroupid: string | undefined;
	setAdgroupid: (adgroupid: string) => void;
	keyword: string | undefined;
	setKeyword: (keyword: string) => void;
	tbclid: string | undefined;
	setTbclid: (tbclid: string) => void;
	obclid: string | undefined;
	setObclid: (obclid: string) => void;
};

export const KeywordContext = React.createContext<KeywordContextInterface>({
	source: undefined,
	setSource: (source: string | undefined) => {},
	sclid: undefined,
	setSclid: (sclid: string | undefined) => {},
	gclid: undefined,
	setGclid: (gclid: string | undefined) => {},
	msclkid: undefined,
	setMsclkid: (msclkid: string | undefined) => {},
	fbclid: undefined,
	setFbclid: (fbclid: string | undefined) => {},
	campaignid: undefined,
	setCampaignid: (campaignid: string | undefined) => {},
	adid: undefined,
	setAdid: (adid: string | undefined) => {},
	adsetid: undefined,
	setAdsetid: (adsetid: string | undefined) => {},
	targetid: undefined,
	setTargetid: (targetid: string | undefined) => {},
	adgroupid: undefined,
	setAdgroupid: (adgroupid: string | undefined) => {},
	keyword: undefined,
	setKeyword: (keyword: string | undefined) => {},
	tbclid: undefined,
	setTbclid: (tbclid: string | undefined) => {},
	obclid: undefined,
	setObclid: (obclid: string | undefined) => {},
});

const KeywordProvider: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	const router = useRouter();
	const [source, setSource] = React.useState<string | undefined>(undefined);
	const [sclid, setSclid] = React.useState<string | undefined>(undefined);
	const [gclid, setGclid] = React.useState<string | undefined>(undefined);
	const [msclkid, setMsclkid] = React.useState<string | undefined>(undefined);
	const [fbclid, setFbclid] = React.useState<string | undefined>(undefined);
	const [adid, setAdid] = React.useState<string | undefined>(undefined);
	const [adsetid, setAdsetid] = React.useState<string | undefined>(undefined);
	const [targetid, setTargetid] = React.useState<string | undefined>(undefined);
	const [keyword, setKeyword] = React.useState<string | undefined>(undefined);
	const [adgroupid, setAdgroupid] = React.useState<string | undefined>(
		undefined
	);
	const [campaignid, setCampaignid] = React.useState<string | undefined>(
		undefined
	);
	const [tbclid, setTbclid] = React.useState<string | undefined>(undefined);
	const [obclid, setObclid] = React.useState<string | undefined>(undefined);

	const cookieGetSet = (
		key: string,
		value: string | undefined,
		setter: (value: string) => void
	) => {
		if (Cookies.get(key) !== value && value !== undefined) {
			Cookies.set(key, value as string);
			setter(value);
		}
	};

	React.useEffect(() => {
		if (!router.isReady) {
			return;
		}

		const {
			sclid,
			msclkid,
			gclid,
			fbclid,
			campaignid,
			adid,
			adsetid,
			targetid,
			adgroupid,
			keyword,
			tbclid,
			obclid,
		} = router.query;

		const getSource = () => {
			if (Cookies.get("sclid") !== undefined) {
				return "savely";
			}

			if (Cookies.get("fbclid") !== undefined) {
				return "facebook";
			}

			if (Cookies.get("gclid") !== undefined) {
				return "google";
			}

			if (Cookies.get("msclkid") !== undefined) {
				return "bing";
			}

			if (Cookies.get("tbclid") !== undefined) {
				return "taboola";
			}

			if (Cookies.get("obclid") !== undefined) {
				return "outbrain";
			}

			return undefined;
		};

		setSource(getSource());
		setSclid(Cookies.get("sclid"));
		setFbclid(Cookies.get("fbclid"));
		setMsclkid(Cookies.get("msclkid"));
		setGclid(Cookies.get("gclid"));
		setCampaignid(Cookies.get("campaignid"));
		setAdid(Cookies.get("adid"));
		setAdsetid(Cookies.get("adsetid"));
		setTargetid(Cookies.get("targetid"));
		setAdgroupid(Cookies.get("adgroupid"));
		setKeyword(Cookies.get("keyword"));
		setTbclid(Cookies.get("tbclid"));
		setObclid(Cookies.get("obclid"));

		cookieGetSet("sclid", sclid as string | undefined, setSclid);
		cookieGetSet("fbclid", fbclid as string | undefined, setFbclid);
		cookieGetSet("gclid", gclid as string | undefined, setGclid);
		cookieGetSet("msclkid", msclkid as string | undefined, setMsclkid);
		cookieGetSet("campaignid", campaignid as string | undefined, setCampaignid);
		cookieGetSet("adid", adid as string | undefined, setAdid);
		cookieGetSet("adsetid", adsetid as string | undefined, setAdsetid);
		cookieGetSet("targetid", targetid as string | undefined, setTargetid);
		cookieGetSet("adgroupid", adgroupid as string | undefined, setAdgroupid);
		cookieGetSet("keyword", keyword as string | undefined, setKeyword);
		cookieGetSet("tbclid", tbclid as string | undefined, setTbclid);
		cookieGetSet("obclid", obclid as string | undefined, setObclid);
	}, [
		router,
		setSource,
		setSclid,
		setFbclid,
		setGclid,
		setMsclkid,
		setCampaignid,
		setAdid,
		setAdsetid,
		setTargetid,
		setAdgroupid,
		setKeyword,
		setTbclid,
		setObclid,
	]);

	return (
		<KeywordContext.Provider
			value={{
				source,
				setSource,
				sclid,
				setSclid,
				gclid,
				setGclid,
				msclkid,
				setMsclkid,
				fbclid,
				setFbclid,
				campaignid,
				setCampaignid,
				adid,
				setAdid,
				adsetid,
				setAdsetid,
				targetid,
				setTargetid,
				adgroupid,
				setAdgroupid,
				keyword,
				setKeyword,
				tbclid,
				setTbclid,
				obclid,
				setObclid,
			}}
		>
			{children}
		</KeywordContext.Provider>
	);
};

export default KeywordProvider;
