import * as React from "react";
import { Address } from "../_types";
import { SolarContextInterface } from "@/types/solarLead";

export const SolarContext = React.createContext<SolarContextInterface>({
	postcode: "",
	setPostcode: (postcode: string) => {},
	homeowner: "",
	setHomeowner: (homeowner: string) => {},
	address: null,
	setAddress: (address: Address) => {},
	firstName: "",
	setFirstName: (firstName: string) => {},
	lastName: "",
	setLastName: (lastName: string) => {},
	contactNumber: "",
	setContactNumber: (contactNumber: string) => {},
	email: "",
	setEmail: (email: string) => {},
	solarSolution: "",
	setSolarSolution: (solarSolution: string) => {},
	typeOfWork: "",
	setTypeOfWork: (typeOfWork: string) => {},
	propertyType: "",
	setPropertyType: (propertyType: string) => {},
	clearState: () => {},
});

const SolarProvider: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	const [email, setEmail] = React.useState<string>("");
	const [address, setAddress] = React.useState<Address | null>(null);
	const [lastName, setLastName] = React.useState<string>("");
	const [postcode, setPostcode] = React.useState<string>("");
	const [firstName, setFirstName] = React.useState<string>("");
	const [typeOfWork, setTypeOfWork] =
		React.useState<string>("new installation");
	const [propertyType, setPropertyType] = React.useState<string>("");
	const [contactNumber, setContactNumber] = React.useState<string>("");
	const [solarSolution, setSolarSolution] = React.useState<string>("");
	const [homeowner, setHomeowner] = React.useState<string>("");

	const clearState = () => {
		setFirstName("");
		setLastName("");
		setEmail("");
		setContactNumber("");
		setPostcode("");
		setAddress(null);
		setHomeowner("");
	};

	return (
		<SolarContext.Provider
			value={{
				postcode,
				setPostcode,
				address,
				setAddress,
				firstName,
				setFirstName,
				lastName,
				setLastName,
				contactNumber,
				setContactNumber,
				email,
				setEmail,
				solarSolution,
				setSolarSolution,
				typeOfWork,
				setTypeOfWork,
				propertyType,
				setPropertyType,
				homeowner,
				setHomeowner,
				clearState,
			}}
		>
			{children}
		</SolarContext.Provider>
	);
};

export default SolarProvider;
